






























































































































































































import { StakingViewModel } from "../viewmodels/staking-viewmodel";
import { Vue, Component, Inject, Prop } from "vue-property-decorator";
import { Observer } from "mobx-vue";
import { walletStore } from "@/stores/wallet-store";

@Observer
@Component({
    components: {
        StakingDialog: () =>
            import("@/modules/staking/dialogs/staking-dialog.vue"),
        ConnectButton: () => import("@/components/ConnectButton.vue"),
    },
})
export default class Stake extends Vue {
    @Inject() vm!: StakingViewModel;
    @Prop({ default: false }) increase!: boolean;
    walletStore = walletStore;
}
